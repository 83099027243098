import WordMatrix from "./WordMatrix";

function App() {

    return (
        <>
            <div className="p-10">
                <WordMatrix/>
            </div>
        </>
    );
}

export default App;
