function WordMatrix() {
    const elements = [
        ['A', 'L', 'Á', 'Z', 'A', 'T', 'O', 'S', 'U', 'C', 'D', 'H', 'Q', 'Y', 'Y', 'M'],
        ['K', 'E', 'D', 'V', 'E', 'S', 'G', 'K', 'A', 'Z', 'A', 'H', 'L', 'N', 'P', 'O'],
        ['T', 'L', 'S', 'W', 'X', 'C', 'G', 'N', 'I', 'F', 'O', 'F', 'N', 'Y', 'F', 'T'],
        ['Í', 'K', 'S', 'Z', 'F', 'Y', 'B', 'O', 'R', 'R', 'N', 'Q', 'N', 'I', 'O', 'I'],
        ['V', 'I', 'E', 'E', 'M', 'P', 'M', 'E', 'K', 'N', 'G', 'X', 'L', 'T', 'G', 'V'],
        ['Z', 'I', 'T', 'H', 'O', 'E', 'S', 'Y', 'C', 'E', 'G', 'I', 'D', 'O', 'É', 'Á'],
        ['O', 'S', 'M', 'W', 'N', 'Z', 'G', 'W', 'S', 'S', 'P', 'K', 'Z', 'T', 'K', 'L'],
        ['F', 'M', 'L', 'Z', 'G', 'S', 'V', 'B', 'G', 'P', 'Ü', 'Y', 'C', 'T', 'O', 'T'],
        ['R', 'E', 'D', 'J', 'O', 'J', 'P', 'E', 'Í', 'Q', 'G', 'L', 'C', 'D', 'N', 'K'],
        ['T', 'R', 'A', 'J', 'L', 'U', 'H', 'N', 'G', 'Z', 'R', 'D', 'E', 'Y', 'Y', 'Y'],
        ['M', 'E', 'S', 'L', 'Y', 'N', 'Ó', 'R', 'I', 'W', 'H', 'U', 'J', 'T', 'N', 'O'],
        ['K', 'T', 'S', 'S', 'D', 'C', 'N', 'H', 'B', 'V', 'N', 'A', 'X', 'J', 'E', 'I'],
        ['J', 'E', 'L', 'K', 'Ö', 'T', 'E', 'L', 'E', 'Z', 'E', 'T', 'T', 'X', 'O', 'S'],
        ['S', 'S', 'J', 'J', 'I', 'N', 'D', 'O', 'N', 'É', 'Z', 'B', 'B', 'Ó', 'I', 'H'],
    ];
    const words = ['MONGOL', 'FILIPPÍNÓ', 'KAZAH', 'KIRGIZ', 'INDONÉZ', 'MOTIVÁLT', 'ELKÖTELEZETT',
        'MEGBÍZHATÓ', 'LELKIISMERETES', 'FOGÉKONY', 'BECSÜLETES', 'NYITOTT', 'ALÁZATOS', 'KEDVES', 'AKTÍV'];
    const listWords = words.map((d) => <li key={d} id={d} className={'mr-4'}>{d}</li>);

    let startLetter, endLetter = {id: '', letter: ''};
    let startCoords, endCoords, drawStart, drawEnd = {x: 0, y: 0};
    let endTarget = null;
    let checkWord = '';
    let highlightCoords = [];
    const drawCSSclass = 'bg-gray-50';
    const letterContainerClass = 'letter_container';
    let letterContainers = document.getElementsByClassName(letterContainerClass);

    const highlightWord = (highlightCoords) => {
        highlightCoords.forEach((coords) => {
            document.getElementById(coords.x + "-" + coords.y).classList.add("bg-green-400");
        })
    };

    const selectHighlight = (startId, pointerId) => {
        drawStart = {x: parseInt(startId.split("-")[0]), y: parseInt(startId.split("-")[1])};
        drawEnd = {x: parseInt(pointerId.split("-")[0]), y: parseInt(pointerId.split("-")[1])};
        for (let letter of letterContainers) {
            letter.classList.remove(drawCSSclass);
        }
        if (drawStart.x === drawEnd.x) {
            let positionY = drawStart.y;
            for (let i = Math.min(drawStart.y, drawEnd.y); i <= Math.max(drawStart.y, drawEnd.y); i++) {
                document.getElementById(drawStart.x + "-" + positionY).classList.add(drawCSSclass);
                positionY = positionY + (Math.sign(drawStart.y - drawEnd.y) * -1);
            }
        } else if (drawStart.y === drawEnd.y) {
            let positionX = drawStart.x;
            for (let i = Math.min(drawStart.x, drawEnd.x); i <= Math.max(drawStart.x, drawEnd.x); i++) {
                document.getElementById(positionX + "-" + drawStart.y).classList.add(drawCSSclass);
                positionX = positionX + (Math.sign(drawStart.x - drawEnd.x) * -1);
            }
        } else if (Math.abs(drawStart.x - drawEnd.x) === Math.abs(drawStart.y - drawEnd.y)) {
            let positionX = drawStart.x;
            let positionY = drawStart.y;
            for (let i = Math.min(drawStart.x, drawEnd.x); i <= Math.max(drawStart.x, drawEnd.x); i++) {
                document.getElementById(positionX + "-" + positionY).classList.add(drawCSSclass);
                positionX = positionX + (Math.sign(drawStart.x - drawEnd.x) * -1);
                positionY = positionY + (Math.sign(drawStart.y - drawEnd.y) * -1);
            }

        }
    };

    const handleEvent = (event) => {
        //console.log(event.type);
        if (event.type === "mousedown" || event.type === "touchstart") {
            event.preventDefault();
            //console.log("DOWN:", event.target.id, event.target.innerText);
            startLetter = {'id': event.target.id, 'letter': event.target.innerText}; // ex. {id: "3-7", letter: "M"}
        } else if (event.type === "touchmove") {
            endTarget = document.elementFromPoint(
                event.changedTouches[0].pageX,
                event.changedTouches[0].pageY
            );
            if (endTarget?.classList.contains(letterContainerClass)) {
                selectHighlight(startLetter.id, endTarget.id);
            }
        } else if (event.type === "mouseup" || event.type === "touchend") {
            event.preventDefault();
            for (let letter of letterContainers) {
                letter.classList.remove(drawCSSclass);
            }
            if (startLetter.id !== '') {
                //console.log("RELEASE:", event.target.id, event.target.innerText, endTarget);
                if (endTarget === null) {
                    endTarget = event.target;
                }
                checkWord = '';
                highlightCoords = [];
                endLetter = {'id': endTarget.id, 'letter': endTarget.innerText};
                startCoords = {
                    x: parseInt(startLetter.id.split("-")[0]),
                    y: parseInt(startLetter.id.split("-")[1])
                };
                endCoords = {x: parseInt(endLetter.id.split("-")[0]), y: parseInt(endLetter.id.split("-")[1])};
                //console.log("Selected:", startLetter, endLetter);
                if (startCoords.x === endCoords.x) {
                    let positionY = startCoords.y;
                    for (let i = Math.min(startCoords.y, endCoords.y); i <= Math.max(startCoords.y, endCoords.y); i++) {
                        checkWord = checkWord + document.getElementById(startCoords.x + "-" + positionY).innerText;
                        highlightCoords.push({x: startCoords.x, y: positionY});
                        positionY = positionY + (Math.sign(startCoords.y - endCoords.y) * -1);
                    }
                    //console.log("Vízszintes", checkWord);
                } else if (startCoords.y === endCoords.y) {
                    let positionX = startCoords.x;
                    for (let i = Math.min(startCoords.x, endCoords.x); i <= Math.max(startCoords.x, endCoords.x); i++) {
                        checkWord = checkWord + document.getElementById(positionX + "-" + startCoords.y).innerText;
                        highlightCoords.push({x: positionX, y: startCoords.y});
                        positionX = positionX + (Math.sign(startCoords.x - endCoords.x) * -1);
                    }
                    //console.log("Függőleges", checkWord);
                } else if (Math.abs(startCoords.x - endCoords.x) === Math.abs(startCoords.y - endCoords.y)) {
                    let positionX = startCoords.x;
                    let positionY = startCoords.y;
                    for (let i = Math.min(startCoords.x, endCoords.x); i <= Math.max(startCoords.x, endCoords.x); i++) {
                        checkWord = checkWord + document.getElementById(positionX + "-" + positionY).innerText;
                        highlightCoords.push({x: positionX, y: positionY});
                        positionX = positionX + (Math.sign(startCoords.x - endCoords.x) * -1);
                        positionY = positionY + (Math.sign(startCoords.y - endCoords.y) * -1);
                    }
                    //console.log("Átlós", checkWord);
                } else {
                    //console.log("Érvénytelen");
                }
                if (checkWord !== '') {
                    if (words.find(word => word === checkWord)) {
                        //console.log("Megtalált szó:", checkWord);
                        highlightWord(highlightCoords);
                        document.getElementById(checkWord).classList.add('line-through');
                    }
                }
                startLetter = {id: '', letter: ''};
                endLetter = {id: '', letter: ''};
                endTarget = null;
            }
        }
    }

    return (
        <>
            <div className="grid grid-cols-16 gap-1 max-w-[960px] ml-auto mr-auto select-none">
                {elements.map((matrixElementRow, matrixElementRowID) => matrixElementRow.map((matrixElement, matrixElementID) =>
                    <div
                        className="bg-amber-400 text-center p-3 cursor-pointer letter_container"
                        key={matrixElementRowID + "-" + matrixElementID}
                        id={matrixElementRowID + "-" + matrixElementID}
                        onTouchStart={handleEvent}
                        onTouchEnd={handleEvent}
                        onTouchMove={handleEvent}
                        onTouchEndCapture={handleEvent}
                        onMouseDown={handleEvent}
                        onMouseUp={handleEvent}>{matrixElement}</div>))}
            </div>
            <ul className={'mt-6 flex flex-wrap items-center justify-center'}>
                {listWords}
            </ul>
        </>
    );
}

export default WordMatrix;
